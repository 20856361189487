import React from 'react'

const CsContext = React.createContext({
  orderCount: 0,
  orderRefetch: () => {},
  ksCount: 0,
  ksOrderRefetch: () => {},
  claimCount: 0,
  claimRefetch: () => {},
})

export default CsContext
