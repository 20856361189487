import React from 'react'
import { Card, CardContent, Typography, Grid, Box, Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { useSnackbar } from 'notistack'
import { useGetReferral } from '@src/shared/api/referrals'
import { useAuth } from '@src/shared/hooks/context/AuthContext'

const getClipboardText = (referral) =>
  `Your one-stop shop for all things automotive!
With over 5,000 SKUs, Automarket makes it easy to find the parts you need. Boost your workshop efficiency with real-time pricing updates and fast delivery.
Sign up today and get rewards worth up to RM1000 by using the referral code ${referral} or click the link below:
${window.location.origin}/signup#signup?${referral}`

const ReferralDetails = () => {
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const { data: referralData } = useGetReferral({ enabled: !!user })

  if (!referralData) return null

  return (
    <Box textAlign={'center'}>
      <Card style={{ marginTop: 20 }}>
        <CardContent>
          <Typography variant='h4' gutterBottom style={{ fontWeight: 'bold' }}>
            Referral Program
          </Typography>
          <Typography variant='body1' color='textSecondary' gutterBottom>
            Invite your friends and earn rewards of up to RM300 per referral!
          </Typography>
          <Box
            style={{
              backgroundColor: '#2AAA8A',
              padding: 15,
              borderRadius: 8,
              marginTop: 10,
              cursor: 'pointer',
            }}
            onClick={() => {
              navigator.clipboard
                .writeText(getClipboardText(referralData.referralCode))
                .then(() => enqueueSnackbar('Copied successfully', { variant: 'success' }))
                .catch((e) => console.error('Failed to copy', e))
            }}
          >
            <Typography
              variant='h4'
              style={{ color: '#FFFFFF', fontWeight: 'bold', marginBottom: 5 }}
            >
              {referralData.referralCode}
            </Typography>
          </Box>
          <Typography
            variant='caption'
            style={{ display: 'block', marginTop: 5, color: '#555555', fontStyle: 'italic' }}
          >
            *Click the code above to copy*
          </Typography>
        </CardContent>
      </Card>

      <Card style={{ marginTop: 20, marginBottom: 20 }}>
        <CardContent>
          <Typography variant='h4' gutterBottom style={{ fontWeight: 'bold' }}>
            History
          </Typography>
          <Grid container spacing={4} alignItems='stretch'>
            {[
              {
                label: 'Total Clicks',
                value: referralData.referralClicks,
                color: '#d32f2f',
                helperText: 'The number of times users clicked the link.',
              },
              {
                label: 'Onboarded Users',
                value: referralData.onboardedUsers,
                color: '#1976d2',
                helperText: 'The number of users who created an account.',
              },
              {
                label: 'Confirmed Users',
                value: referralData.confirmedUsers,
                color: '#388e3c',
                helperText: 'The number of users who completed a purchase.',
              },
            ].map((stat, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Box
                  position='relative'
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  textAlign='center'
                  p={2}
                  height='100%'
                  borderRadius={8}
                  color={'white'}
                  style={{ backgroundColor: stat.color }}
                >
                  <Box position='absolute' top={8} right={8}>
                    <Tooltip title={stat.helperText}>
                      <InfoIcon fontSize='small' style={{ color: 'white', cursor: 'pointer' }} />
                    </Tooltip>
                  </Box>
                  <Typography variant='body1' gutterBottom>
                    {stat.label}
                  </Typography>
                  <Typography variant='h5' fontWeight={'bold'}>
                    {stat.value}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Typography
            variant='caption'
            style={{
              display: 'block',
              marginTop: '5px',
              color: '#555555',
              fontStyle: 'italic',
            }}
          >
            *Disclaimer: The statistics are preliminary and subject to review at the end of each
            month.*
          </Typography>
        </CardContent>
      </Card>
    </Box>
  )
}

export default ReferralDetails
