const { BRAKE } = require('../constants/companies')
const { uniq } = require('lodash')
const { htgCompanyOptions } = require('../config/regional-configs')
const { AMOUNT, PERCENTAGE, GIFT } = require('../constants/promotion-types')

const getTotalQty = (orderItems) =>
  orderItems?.length ? orderItems.reduce((total, item) => (total += item.quantity), 0) : 0

const getSubtotal = (orderItems) =>
  orderItems?.length
    ? orderItems.reduce((total, item) => (total += item.unitPrice * item.quantity), 0)
    : 0

const hasPromotionsApplied = (orderItems, promoCodeValid) =>
  promoCodeValid || orderItems?.some((item) => item?.promotions?.length >= 1)

const getTotalDiscount = (orderItems, promoCodeObj) => {
  const orderItemsDiscount = orderItems?.length
    ? orderItems.reduce(
        (total, item) =>
          (total += (item.unitDiscount || 0) * item.quantity + item.totalFocDiscount),
        0
      )
    : 0

  const promoCodeDiscountAmount = promoCodeObj?.type === AMOUNT ? promoCodeObj?.discountAmount : 0

  const subtotal = getSubtotal(orderItems)
  const promoCodeDiscountPercentageAmount =
    promoCodeObj?.type === PERCENTAGE
      ? subtotal * (promoCodeObj?.discountPercentage ?? 0) >= promoCodeObj?.discountCap ?? 0
        ? promoCodeObj?.discountCap
        : subtotal * (promoCodeObj?.discountPercentage ?? 0)
      : 0

  return orderItemsDiscount + promoCodeDiscountAmount + promoCodeDiscountPercentageAmount
}

const getTotalTax = (orderItems) =>
  orderItems?.length
    ? orderItems.reduce((total, item) => (total += (item.unitTax || 0) * item.quantity), 0)
    : 0

const getTotal = (orderItems, promoCodeObj) => {
  const orderItemsTotal = orderItems?.length
    ? orderItems.reduce(
        (total, item) =>
          (total +=
            item.subTotal ||
            (item.unitPrice - (item.unitDiscount || 0) + (item.unitTax || 0)) * item.quantity -
              item.totalFocDiscount),
        0
      )
    : 0

  const promoCodeDiscountAmount = promoCodeObj?.type === AMOUNT ? promoCodeObj?.discountAmount : 0

  const subtotal = getSubtotal(orderItems)
  const promoCodeDiscountPercentageAmount =
    promoCodeObj?.type === PERCENTAGE
      ? subtotal * (promoCodeObj?.discountPercentage ?? 0) >= promoCodeObj?.discountCap
        ? promoCodeObj?.discountCap ?? 0
        : subtotal * (promoCodeObj?.discountPercentage ?? 0)
      : 0

  return orderItemsTotal - promoCodeDiscountPercentageAmount - promoCodeDiscountAmount
}

const getPromos = (orderItems) =>
  orderItems
    ?.map((item) =>
      (item.promotions || item.appliedPromotions)?.map((promo) => ({
        id: promo.id,
        type: promo.type,
        freeGift: promo.freeGift,
        freeGiftCount: item.freeGiftCount,
      }))
    )
    ?.flat()
    ?.reduce(
      (acc, promo) =>
        !!acc.find((uniquePromo) => uniquePromo?.id === promo?.id) ? acc : [...acc, promo],
      []
    ) || []

const getFreeGifts = (orderItems, promoCodeObj) => {
  const orderItemFreeGifts = getPromos(orderItems)
    .filter((item) => item?.type === GIFT)
    .map((item) => `${item?.freeGift} x ${item?.freeGiftCount} Pcs`)
    .filter((v, i, a) => a.indexOf(v) === i)

  const promoCodeFreeGifts =
    promoCodeObj?.type === GIFT && promoCodeObj.freeGift
      ? [`${promoCodeObj?.freeGift} x ${promoCodeObj?.freeGiftCount} Pcs`]
      : []

  return [...orderItemFreeGifts, ...promoCodeFreeGifts]
}

const checkCredits = (country, companyAccounts = [], orderItems = []) => {
  const exceededCompanyAccounts = []
  for (const companyAccount of companyAccounts) {
    if (companyAccount?.htgCompany !== BRAKE) {
      if (companyAccount?.amountDue > 0) {
        exceededCompanyAccounts.push(companyAccount.htgCompany)
        continue
      }
      const totalByProductType = getTotal(
        orderItems.filter(({ productType }) =>
          htgCompanyOptions[country]
            .find((htgCompany) => htgCompany.value === companyAccount.htgCompany)
            ?.productTypes?.includes(productType)
        )
      )
      const balanceAfterPurchase = companyAccount?.availableCredit - totalByProductType
      if (balanceAfterPurchase < 0) {
        exceededCompanyAccounts.push(companyAccount.htgCompany)
      }
    }
  }
  return uniq(exceededCompanyAccounts)
}

const getTotalReferralDiscount = (orderItems) =>
  orderItems?.length
    ? orderItems.reduce(
        (total, { referralDiscountAmount = 0 }) => total + referralDiscountAmount,
        0
      )
    : 0

module.exports = {
  getTotalQty,
  getSubtotal,
  getTotal,
  checkCredits,
  getTotalDiscount,
  getFreeGifts,
  getPromos,
  getTotalTax,
  hasPromotionsApplied,
  getTotalReferralDiscount,
}
