import { useQuery, useMutation } from 'react-query'
import API from '.'

const ENDPOINT = '/api/referral'
const QUERY_KEY = 'referral'

export const useGetReferral = (config = {}) =>
  useQuery([QUERY_KEY, 'list'], async () => (await API.get(ENDPOINT)).data, config)

export const useGetRebateStats = (config = {}) =>
  useQuery(
    [QUERY_KEY, 'list', 'rebate-stats'],
    async () => (await API.get(`${ENDPOINT}/rebate-stats`)).data,
    config
  )

export const usePostTrackLinkClick = (config = {}) =>
  useMutation((newData) => API.post(ENDPOINT, newData).catch(() => {}), config)

export const useExportPromoters = (config = {}) =>
  useMutation(() => API.get(`${ENDPOINT}/export-promoters`, { responseType: 'blob' }), config)

export const useExportAllReferralStats = (config = {}) =>
  useMutation(() => API.get(`${ENDPOINT}/export-referral-stats`, { responseType: 'blob' }), config)
