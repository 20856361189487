import React from 'react'
import { Typography, Box, LinearProgress, Card, CardContent, Divider } from '@material-ui/core'
import { useGetRebateStats } from '@src/shared/api/referrals'
import { useAuth } from '@src/shared/hooks/context/AuthContext'

const RebateStats = () => {
  const { user } = useAuth()

  const { data } = useGetRebateStats({ enabled: !!user })

  if (!data) return null

  return (
    <Card>
      <CardContent>
        <Box mb={3} textAlign='center'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 'bold',
              color: '#6c63ff',
              textTransform: 'uppercase',
              letterSpacing: 1,
            }}
          >
            Referral Campaign
          </Typography>
          <Typography
            variant='h5'
            style={{
              fontWeight: 'bold',
              marginTop: 4,
              marginBottom: 8,
              color: '#333',
            }}
          >
            {data.htgCompany}
          </Typography>
          <Divider
            style={{
              backgroundColor: '#6c63ff',
              height: 2,
              width: '50%',
              margin: 'auto',
            }}
          />
        </Box>
        <Box mb={3}>
          <Typography variant='subtitle1' style={{ fontWeight: 'bold', marginBottom: 8 }}>
            {data.progress}% USED
          </Typography>
          <LinearProgress
            variant='determinate'
            value={data.progress}
            style={{ height: 10, borderRadius: 5, backgroundColor: '#e0e0e0' }}
          />
        </Box>
        <Box display='flex' justifyContent='space-between' textAlign='center'>
          <Box>
            <Typography variant='h6' style={{ fontWeight: 'bold' }}>
              RM{data.usedDiscountAmount}
            </Typography>
            <Typography variant='caption' color='textSecondary'>
              DISCOUNT USED
            </Typography>
          </Box>
          <Box>
            <Typography variant='h6' style={{ fontWeight: 'bold' }}>
              RM{data.maxDiscountAmount}
            </Typography>
            <Typography variant='caption' color='textSecondary'>
              MAXIMUM DISCOUNT
            </Typography>
          </Box>
          <Box>
            <Typography variant='h6' style={{ fontWeight: 'bold' }}>
              {data.daysToGo}
            </Typography>
            <Typography variant='caption' color='textSecondary'>
              DAYS TO GO
            </Typography>
          </Box>
        </Box>
        <Box textAlign='center' mt={2}>
          <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>
            {data.period}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default RebateStats
